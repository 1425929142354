var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.show)?_c('v-container',[_c('automation-stepper',{attrs:{"id":"automation-stepper"}}),(_vm.automationRule)?[[_c('v-row',{staticClass:"subHeaderRow",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('h1',[_vm._v("Scheduled Campaign Rules")])])],1)],_c('v-row',{attrs:{"no-gutters":""}},[_c('v-simple-table',{attrs:{"id":"recent-campaigns"},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',{staticStyle:{"background-color":"#D9DAFF"}},[_c('tr',[_c('th',{staticClass:"text-center"},[_vm._v(" Rule Name ")]),_c('th',{staticClass:"text-center"},[_vm._v(" Sheet URL ")]),_c('th',{staticClass:"text-center"},[_vm._v(" Email Template ")]),_c('th',{staticClass:"text-center"},[_vm._v(" Date ")]),_c('th',{staticClass:"text-center"},[_vm._v(" Time ")]),_c('th',{staticClass:"text-center"},[_vm._v(" Frequency ")]),_c('th',{staticClass:"text-center"},[_vm._v("Actions")])])]),_c('tbody',[_vm._l((_vm.automationRule),function(item){return [_c('tr',{key:item.id},[_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(item.rule_name)+" ")]),_c('td',{staticClass:"text-center sheet-url"},[_c('a',{attrs:{"href":item.sheet_url,"target":"_blank"}},[_vm._v("Spreadsheet")])]),_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(item.template_name)+" ")]),_c('td',{staticClass:"text-center"},[_c('span',[_vm._v(_vm._s(item.send_date))])]),_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.tConvert(item.send_time))+" ")]),(
                    item.frequency.toString().toLowerCase().includes('annually') ||
                    item.frequency.value && item.frequency.value.toString().toLowerCase().includes('annually')
                )?_c('td',{staticClass:"text-center"},[_vm._v(" Every Year ")]):(
                    item.frequency.toString().toLowerCase().includes('monthly') || 
                    item.frequency.value && item.frequency.value.toString().toLowerCase().includes('monthly'))?_c('td',{staticClass:"text-center"},[_vm._v(" Every Month ")]):(
                    item.frequency.toString().toLowerCase().includes('weekly') || 
                    item.frequency.toString().toLowerCase().includes('week') ||
                    (item.frequency.value && item.frequency.value.toString().toLowerCase().includes('weekly')) ||
                    (item.frequency.value && item.frequency.value.toString().toLowerCase().includes('week'))
                  )?_c('td',{staticClass:"text-center"},[_vm._v(" Every Week ")]):(
                  item.frequency.toString().toLowerCase().includes('daily') ||
                  item.frequency.value && item.frequency.value.toString().toLowerCase().includes('daily')
                )?_c('td',{staticClass:"text-center"},[_vm._v(" Daily ")]):(
                  item.frequency.toString().toLowerCase().includes('none') ||
                  item.frequency.value && item.frequency.value.toString().toLowerCase().includes('none'))?_c('td',{staticClass:"text-center"},[_vm._v(" None ")]):_c('td',{staticClass:"text-center"}),_c('td',{staticClass:"more-options text-center"},[_c('a',{on:{"click":function($event){return _vm.editRule(item)}}},[_c('v-icon',[_vm._v("edit")])],1),_c('transition',{attrs:{"name":"fade-transition"}},[(_vm.ruleSelectedID == item.id)?_c('div',{staticClass:"update-automation-box"},[_c('update-automation-rule',{attrs:{"rule":item},on:{"close":function($event){return _vm.cancel()}}})],1):_vm._e()]),_c('a',{on:{"click":function($event){return _vm.deleteCampaign(item)}}},[(_vm.selectedID == item.id)?_c('v-progress-circular',{attrs:{"indeterminate":"","color":"#777BD2"}}):_vm._e(),(_vm.selectedID != item.id)?_c('v-icon',{staticClass:"deleteBtn"},[_vm._v("delete")]):_vm._e()],1),_c('transition',{attrs:{"name":"fade-transition"}},[(_vm.dialogId == item.id)?_c('confirmation-dialog',{attrs:{"actionName":"deleteAutomationRule","primaryTxt":"Delete","item":item},on:{"close":function($event){return _vm.cancel()}}}):_vm._e()],1)],1)])]})],2)]},proxy:true}],null,false,3228158189)}),(!_vm.automationRule.length && !_vm.fetching)?[_c('p',{staticClass:"empty-campaign-msg"},[_vm._v(" No Rule has been added. Get started by adding a new rule. ")])]:_vm._e()],2)]:_vm._e(),(_vm.fetching)?_c('div',{staticClass:"loader"},[_c('v-progress-circular',{staticClass:"progressLoader",attrs:{"indeterminate":"","color":"#777BD2"}})],1):_vm._e()],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }