<template>
    <v-container fill-height>
        <v-card class="elevation-4 ruleCard">
            <v-card-text>
                <h1>Update Automation Rule</h1>
                <v-form ref="templateForm" lazy-validation>
                    <v-text-field outlined dense name="ruleName" label="Rule Name" type="text" v-model="rule.rule_name" required color="#777BD2" :rules="ruleNameCheck"></v-text-field>
                    <v-text-field outlined dense name="sheetURL" label="Sheet URL" type="text" v-model="rule.sheet_url" required color="#777BD2" :rules="sheetURLRule">
                    </v-text-field>
                    <v-select 
                        dense outlined v-model="rule.template_name"
                        :items="emailTemplatesItems" :rules="emailTemplateRule"
                    ></v-select>
                    <div class="send-date">
                        <strong>Date: </strong>
                        <input type="date" id="datePicker" v-model="rule.send_date" min="2021-10-01" @change="changeDate()">
                    </div>
                    <div class="send-date send-time">
                        <strong>Time: </strong>
                        <!-- <input type="time" id="timePicker" v-model="rule.send_time" min="00:00"> -->
                        <!-- <vue-timepicker format="hh:mm A" :minute-interval="30" v-model="rule.send_time" @change="changeTime()" hide-clear-button close-on-complete auto-scroll class="time-picker"  placeholder="HH:MM"></vue-timepicker> -->
                        <vue-timepicker fixed-dropdown-button :hour-range="[[8, 17]]" hide-disabled-hours :minute-interval="30" v-model="rule.send_time" @change="changeTime()" hide-clear-button close-on-complete type="time" class="time-picker" placeholder="HH:MM">
                            <template v-slot:dropdownButton>
                            <img class="time-custom-icon" src='@/assets/img/time-icon.png' /> 
                            </template>
                        </vue-timepicker>
                    </div>
                    <v-select
                        :items="frequencyItems"
                        item-text="text"
                        item-value="value"
                        return-object
                        dense
                        outlined
                        v-model="rule.frequency"
                    ></v-select>
                    <v-checkbox
                        v-model="rule.new_entries"
                        label="Send emails to newly added email addressess"
                    ></v-checkbox>
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-btn elevation="0" id="cancelTemplateBtn" @click="$emit('close')" style="margin-left:auto" :disabled="rulesActionInProgress">Cancel</v-btn>
                <v-btn elevation="0" id="addTemplateBtn" class="primary" @click="updateRule" :disabled="rulesActionInProgress">
                    <v-progress-circular
                        indeterminate
                        color="#fff"
                        v-if="rulesActionInProgress"
                    ></v-progress-circular>
                    <span id="emailTemplatePrimaryBtnText">Update</span>
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-container>
</template>
<script>
// Main JS (in UMD format)
import VueTimepicker from 'vue2-timepicker'

// CSS
import 'vue2-timepicker/dist/VueTimepicker.css'
export default {
    name: 'updateAutomationRule',
    props: ['rule'],
    components: { VueTimepicker },
    data(){
        return{
            frequencyItems: [],
            emailTemplatesItems: [],
            sheetURLRule: [(v) => /^(https?|ftp):\/\/([a-zA-Z0-9.-]+(:[a-zA-Z0-9.&%$-]+)*@)*((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3}|([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2}))(:[0-9]+)*(\/($|[a-zA-Z0-9.,?'\\+&%$#=~_-]+))*$/.test(v) || "URL is not valid"],
            emailTemplateRule: [(v) => !!v || "Email template is required"],
            ruleNameCheck: [(v) => !!v || "Rule Name is required"],
            sheetIDRule: [(v) => !!v || "Sheet ID is required"],
        }
    },
    mounted(){
        this.showEmailTemplates();
        this.changeDate();
        var _this = this
        this.emailTemplatesData.forEach(element => {
            _this.emailTemplatesItems.push(element.name);
        });
    },
    computed:{
        rulesActionInProgress(){
            return this.$store.state.rulesActionInProgress;
        },
        userEmail(){
            return this.$store.state.user.attributes.email;
        },
        emailTemplatesData() {
            var x = "";
            if (
                (this.$store.state.EmailTemplates == undefined ||
                this.$store.state.EmailTemplates == null) &&
                this.userEmail != ""
            ) {
                this.showEmailTemplates();
            } else if (this.$store.state.EmailTemplates && this.userEmail != "") {
                x = this.$store.state.EmailTemplates.data;
            }
            return x;
        },
    },
    methods:{
        updateRule(){
            if (this.$refs.templateForm.validate()) {
                if(this.$store.state.rulesActionInProgress == false){
                    this.$store.state.rulesActionInProgress = true;
                    var sheetID = new RegExp("/spreadsheets/d/([a-zA-Z0-9-_]+)").exec(this.rule.sheet_url)[1];
                    var json = {
                        "user_email": this.userEmail,
                        "rule_name": this.rule.rule_name,
                        "template_name": this.rule.template_name,
                        "sheet_url": this.rule.sheet_url,
                        "sheet_id" : sheetID,
                        "send_time": this.rule.send_time,
                        "send_date": this.rule.send_date,
                        "frequency": this.rule.frequency,
                        "new_entries": this.rule.new_entries
                    }
                    console.log(json);
                    this.$store.dispatch('updateAutomationRule', {ruleID: this.rule.id, userJson: json})
                }
            }
        },
        changeTime(){
            if(this.timePicker == null || this.timePicker == undefined || this.timePicker == []){
                this.timePicker = '00:00';
            }
        },
        showEmailTemplates() {
            this.$store.state.gettingTemplatesInProgress = true;
            this.$store.dispatch("getEmailTemplates", this.userEmail);
        },
        changeDate(){
            var x, date, weeklyDay, weekdayOccurence, weekdays = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'], forMonth, occurence = ['first', 'second', 'third', 'fourth', 'fifth'], yearly;
            var today = new Date();
            // var epochTime = today.getTime();
            // epochTime = epochTime + 0/* + 86400000*/;
            // today = new Date(epochTime);
            var dd = today.getDate(); //min is one date more than today
            var mm = today.getMonth() + 1; //January is 0!
            var yyyy = today.getFullYear();
            if (dd < 10) {
                dd = '0' + dd;
            }

            if (mm < 10) {
                mm = '0' + mm;
            } 
                
            today = yyyy + '-' + mm + '-' + dd;
            document.getElementById("datePicker").setAttribute("min", today);
            if(this.datePicker == null || this.datePicker == undefined || this.datePicker == []){
                this.datePicker = today;
            }
            if(this.datePicker){
                var parts = this.datePicker.split('-');
                date = new Date(parts[0], parts[1] - 1, parts[2]);
                weeklyDay = this.getDayName(date, 'en');
                forMonth = 'Monthly on the '+weeklyDay;
                weekdayOccurence = this.getAllDaysInMonth(date.getFullYear(), date.getMonth()+1, weekdays.indexOf(weeklyDay)+1);
                weekdayOccurence.some((element,index) => {
                x = new Date(element);
                if(date.getDate() == x.getDate() && (index+1) < weekdayOccurence.length){
                    forMonth = {text:'Every Month', value:'Monthly on the '+occurence[index]+' '+weeklyDay};
                }
                else if(date.getDate() == x.getDate() && (index+1) == weekdayOccurence.length){
                    forMonth = {text:'Every Month', value:'Monthly on the last '+weeklyDay};
                }
                });
                const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
                yearly = {text:'Every Year', value:'Annually on '+monthNames[date.getMonth()]+' '+date.getDate()};
            }

            this.frequencyItems = [
                {text:'Daily', value:'Daily'}, /* {text:'Every Weekday (Monday to Friday)', value:'Every Weekday (Monday to Friday)'}, */ {text:'Every Week', value:'Weekly on '+weeklyDay}, forMonth, yearly, {text:'Daily', value:'Daily'}, {text:'None', value:'None'}];
        },
        getDayName(dateStr, locale)
        {
            var date = new Date(dateStr);
            return date.toLocaleDateString(locale, { weekday: 'long' });        
        },
        // getMonthlyDate(dateStr){
        //   var x = new Date(dateStr);
        //   var date = x.getDay();
        // },
        getAllDaysInMonth(year, month, dayNumber) {
            var d = new Date(year, --month, 1);
            var dates = [];
            var daysToFirst = (dayNumber + 7 - d.getDay()) % 7;
            var firstOf = new Date(d.setDate(d.getDate() + daysToFirst));

            while (firstOf.getMonth() == month) {
                dates.push(new Date(+firstOf));
                firstOf.setDate(firstOf.getDate() + 7);
            }
            return dates;
        },
    }
}
</script>
<style scoped>
.ruleCard{
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 50vw;
    max-height: 90vh;
    overflow-y: auto;
    padding: 25px;
    text-align: left;
}
h1{
    padding: 10px 0px 35px 0px;
    text-align: center;
}
.ruleCard input{
    width: 150px;
}
.ruleCard input, .time-picker, .ruleCard strong{
    font-size: 16px;
    color: #444;
    margin-bottom: 15px;
}
.send-time strong{
    position: relative;
    top: -5px;
}
.ruleCard .send-date{
    display: block;
}

</style>