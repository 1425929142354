<template>
  <v-stepper v-model="e1" class="elevation-0 getStarted">
    <v-stepper-header>
      <v-stepper-step :complete="e1 > 1" step="1">
        Instructions
      </v-stepper-step>
      <v-divider></v-divider>
      <v-stepper-step :complete="e1 > 2" step="2">
        Add Google Sheet
      </v-stepper-step>
      <v-divider></v-divider>
      <v-stepper-step :complete="e1 > 3" step="3">
        Select Template
      </v-stepper-step>

      <v-divider></v-divider>

      <v-stepper-step :complete="e1 > 4" step="4"> Select Schedule </v-stepper-step>
      <v-divider></v-divider>

      <v-stepper-step step="5"> Save </v-stepper-step>
    </v-stepper-header>

    <v-stepper-items>
      <v-stepper-content step="1">
        <v-card class="mb-12 elevation-0" color="lighten-1">
          <h3>Kindly follow the following instructions to integrate Google Sheet and create a Rule:</h3>
          <ol>
            <li>Share the sheet you want to attach with the service account <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <strong 
                    v-bind="attrs" v-on="on" 
                    @mouseout="mouseOutFunc()"
                    @click="copyToClipboard()"
                    id="service-account"
                  >{{serviceAccount}}</strong>
                </template>
                <span>{{tooltipText}}</span>
              </v-tooltip>
            </li>
            <li>Copy whole sheet URL and paste it in the "Sheet URL" field</li>
            <li>Verify the sheet (if it exists and has permissions) by clicking on the verify button. Once verified, move on to the next step</li>
            <li>Select Email Template you want to send or create a new email template for the rule.</li>
            <li>In the next step Select Date, Time and Frequency for the Rule. and after that create the Rule.</li>
          </ol>
        </v-card>

        <v-card-actions class="text-right upload-csv-actions">
          <v-btn class="right-btn" color="primary" @click="continueToSheet()">
            Continue
          </v-btn>
        </v-card-actions>

        
      </v-stepper-content>


      <v-stepper-content step="2">
        <v-card class="mb-12 elevation-0" color="lighten-1">
          <v-row no-gutters>
            <v-col cols="12" sm="12">
              <v-text-field
                v-model="ruleName"
                dense
                :placeholder="'Enter rule name here'"
                :rules="ruleNameCheck"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="12" sm="10">
              <v-text-field
                v-model="sheetURL"
                dense
                :placeholder="'Enter sheet URL here'"
                :rules="sheetURLRule"
                @change="resetSheetVerification()"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="2">
              <v-btn class="elevation-0 verify-btn" color="primary" :disabled="sheetVerified || verifyingGoogleSheet" @click="verifyGoogleSheet()"><v-progress-circular
              indeterminate
              color="#fff"
              v-if="verifyingGoogleSheet"
              class="progressLoader"
            ></v-progress-circular><span v-if="!verifyingGoogleSheet">Verify</span></v-btn>
            </v-col>
          </v-row>
          <p>Please paste the sheet URL in above input field</p>

        </v-card>

        <v-card-actions class="text-right upload-csv-actions">
          <v-btn class="right-btn" @click="e1 = 1" text> Back </v-btn>
          <v-btn color="primary" :disabled="validNameAndURL(sheetURL, ruleName)" @click="toSelectEmailTemplate()">
            Continue
          </v-btn>
        </v-card-actions>

        
      </v-stepper-content>

      <v-stepper-content step="3">
        <v-card class="mb-12 elevation-0 selectEmailTemplate" color="lighten-1">
          <v-row>
            <v-form
              ref="emailForm"
              v-model="validEmail"
              lazy-validation
              style="width: 100%"
            >
              <v-radio-group
                v-model="stepperCampaignTemplate"
                row
                :rules="emailTemplateRule"
                @change="checkEmailTemplate"
                id="templateRadio"
              >
                <v-col
                  cols="12"
                  sm="3"
                  v-for="(item, index) in emailTemplatesData"
                  :key="item.id"
                >
                  <v-card
                    class="elevation-0"
                    @click="
                      stepperCampaignTemplate = item.name;
                      checkEmailTemplate();
                    "
                  >
                    <v-card-title>{{ item.name }}</v-card-title>
                    <v-card-text>
                      <b>Subject: {{ item.subject }}</b
                      ><br />
                      <div
                        :id="item.name + '-emailTempBody'"
                        class="emailTempBody"
                      >
                        <span :class="getOverflowClass(item.name)">{{
                          item.body
                        }}</span>
                      </div>
                      <p v-if="item.attachment_name">
                        Attachment:
                        <a
                          @click="
                            downloadBlob(
                              downloadURL[index],
                              item.attachment_name.replace(/\_@.*\./, '.'),
                              index
                            )
                          "
                          >{{ item.attachment_name.replace(/\_@.*\./, ".") }}</a
                        >
                      </p>
                    </v-card-text>
                    <v-card-actions class="more-options text-center">
                      <v-icon
                        @click="openAddEmailTemplateBox('Update', item)"
                        :class="'disabled-' + templateActionBtnDisabled"
                        >edit</v-icon
                      >
                      <a
                        @click="deleteEmailTemplate(item)"
                        :class="'disabled-' + templateActionBtnDisabled"
                      >
                        <v-progress-circular
                          indeterminate
                          color="#777BD2"
                          v-if="selectedID == item.id"
                        ></v-progress-circular>
                        <v-icon v-if="selectedID != item.id" class="deleteBtn"
                          >delete</v-icon
                        >
                      </a>
                      <transition name="fade-transition"
                        ><confirmation-dialog
                          v-if="dialogId == item.id"
                          actionName="deleteEmailTemplate"
                          primaryTxt="Delete"
                          :item="item"
                          @close="dialogId = ''"
                        ></confirmation-dialog
                      ></transition>
                    </v-card-actions>
                  </v-card>
                  <v-radio color="#777BD2" :value="item.name"></v-radio>
                </v-col>
                <v-col cols="12" sm="3" class="addEmailTemplate">
                  <v-card
                    class="elevation-0"
                    @click="openNewAddEmailTemplateBox('add')"
                  >
                    <v-card-text>
                      <v-icon>add</v-icon>
                      <p>Create New Email Template</p>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-radio-group>
            </v-form>
          </v-row>
        </v-card>

        <v-card-actions class="text-right upload-csv-actions">
          <v-btn class="right-btn" @click="e1 = 2" text> Back </v-btn>
          <v-btn color="primary" :disabled="!stepperCampaignTemplate" @click="e1 = 4; changeDate(); changeTime();">
            Continue
          </v-btn>
        </v-card-actions>
      </v-stepper-content>

      <v-stepper-content step="4">
        <v-card class="mb-12 automation-scheduler elevation-0" style="max-height: 100%; height: 290px" color="lighten-1">
          <v-row>
            <v-col cols="12" sm="12">
              <h3>Date:</h3>
              <input type="date" id="datePicker" v-model="datePicker" :min="getTodayDate()" @change="changeDate()">
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="12">
              <h3>Time:</h3>
              <!-- <input type="time" id="timePicker" v-model="timePicker" min="00:00" @change="changeTime()"> -->
              <!-- <vue-timepicker fixed-dropdown-button format="hh:mm A" :minute-interval="30" v-model="timePicker" @change="changeTime()" hide-clear-button close-on-complete auto-scroll type="time" class="time-picker" placeholder="HH:MM"> -->
              <!-- <vue-timepicker fixed-dropdown-button :hour-range="[[0, 23]]" hide-disabled-hours :minute-interval="10" v-model="timePicker" @change="changeTime()" hide-clear-button close-on-complete type="time" class="time-picker" placeholder="HH:MM"> -->
              <vue-timepicker fixed-dropdown-button :hour-range="[[8, 17]]" hide-disabled-hours :minute-interval="30" v-model="timePicker" @change="changeTime()" hide-clear-button close-on-complete type="time" class="time-picker" placeholder="HH:mm">
                <template v-slot:dropdownButton>
                  <img class="time-custom-icon" src='@/assets/img/time-icon.png' /> 
                </template>
              </vue-timepicker>
            </v-col>
          </v-row>
          <v-row class="frequency">
            <h3>Frequency:</h3>
            <v-select
              :items="frequencyItems"
              item-text="text"
              item-value="value"
              return-object
              dense
              outlined
              v-model="frequency"
            ></v-select>
          </v-row>
        </v-card>

        <v-card-actions class="text-right upload-csv-actions">
          <v-btn class="right-btn" @click="e1 = 3" text> Back </v-btn>
          <v-btn color="primary" :disabled="frequencyDisabled" @click="dateAndTimeSubmitted">
            Continue
          </v-btn>
        </v-card-actions>
      </v-stepper-content>
      <v-stepper-content step="5">
        <v-card class="mb-12 elevation-0 final-card" color="lighten-1">
          <v-row>
            <v-col cols="12" sm="12">
              <v-checkbox
                v-model="newEmailsOnly"
                label="Only include new email addresses"
              ></v-checkbox>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" sm="6">
              <h3>Rule Name:</h3>
              <p>{{ruleName}}</p>
            </v-col>
            <v-col cols="12" sm="6">
              <h3>Sheet URL:</h3>
              <a :href="sheetURL" target="_blank">Spreadsheet</a>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" sm="3">
              <h3>Template Name:</h3>
              <p>{{stepperCampaignTemplate}}</p>
            </v-col>
            <v-col cols="12" sm="3">
              <h3>Date:</h3>
              <p>{{datePicker}}</p>
            </v-col>
            <v-col cols="12" sm="3">
              <h3>Time:</h3>
              <!-- <p>{{tConvert(timePicker)}}</p> -->
              <p>{{timePicker}}</p>
            </v-col>
            <v-col cols="12" sm="3">
              <h3>Frequency:</h3>
              <p>{{frequency.text}}</p>
            </v-col>
          </v-row>
        </v-card>

        <v-card-actions class="text-right upload-csv-actions">
          <v-btn class="right-btn" @click="e1 = 4" text> Back </v-btn>
          <v-btn class="right-btn" v-if="$store.state.automationUpdateBtn" color="primary" @click="updateScheduler()">
            <v-progress-circular
              indeterminate
              color="#fff"
              v-if="$store.state.rulesActionInProgress"
              class="progressLoader"
            ></v-progress-circular><span v-if="!$store.state.rulesActionInProgress">Update</span>
          </v-btn>
          <v-btn v-if="$store.state.automationCreateBtn"  color="primary" @click="saveScheduler()">
            <v-progress-circular
              indeterminate
              color="#fff"
              v-if="$store.state.rulesActionInProgress"
              class="progressLoader"
            ></v-progress-circular><span v-if="!$store.state.rulesActionInProgress">Create</span>
          </v-btn>

          
        </v-card-actions>
      </v-stepper-content>
    </v-stepper-items>
  </v-stepper>
</template>

<script>
import ConfirmationDialog from '@/components/ConfirmationDialog.vue';
import { Storage } from 'aws-amplify';
// Main JS (in UMD format)
import VueTimepicker from 'vue2-timepicker'

// CSS
import 'vue2-timepicker/dist/VueTimepicker.css'
export default {
  components: { ConfirmationDialog, VueTimepicker },
  name: 'AutomationStepper',
  data() {
    return {
      validEmail: false,
      /* eslint-disable-next-line */
      sheetURLRule: [(v) => /^(https?|ftp):\/\/([a-zA-Z0-9.-]+(:[a-zA-Z0-9.&%$-]+)*@)*((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3}|([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2}))(:[0-9]+)*(\/($|[a-zA-Z0-9.,?'\\+&%$#=~_-]+))*$/.test(v) || "URL is not valid"],
      emailTemplateRule: [(v) => !!v || "Email template is required"],
      ruleNameCheck: [(v) => !!v || "Rule Name is required"],
      sheetIDRule: [(v) => !!v || "Sheet ID is required"],
      frequencyItems: [{text:'', value:''}],
      downloadURL: [],
      dateObject: new Date(),
      newEmailsOnly: true,
      serviceAccount: 'aws-lambda-access@abee-ems.iam.gserviceaccount.com',
      tooltipText: 'Copy to clipboard',
    };
  },
  computed: {
    e1:{
      set(value){
        this.$store.state.stepperPagination = value;
      },
      get(){
        return this.$store.state.stepperPagination;
      }
    },
    verifyingGoogleSheet: {
      set(value){
        this.$store.state.verifyingGoogleSheet = value;
      },
      get(){
        return this.$store.state.verifyingGoogleSheet;
      }
    },
    sheetVerified:{
      set(value){
        this.$store.state.sheetVerified = value;
      },
      get(){
        return this.$store.state.sheetVerified;
      }
    },
    datePicker:{
      set(value){
        this.$store.state.datePicker = value
      },
      get(){
        return this.$store.state.datePicker;
      }
    },
    timePicker:{
      set(value){
        this.$store.state.timePicker = value
      },
      get(){
        return this.$store.state.timePicker;
      }
    },
    frequency:{
      set(value){
        this.$store.state.frequency = value
      },
      get(){
        return this.$store.state.frequency;
      }
    },
    sheetURL:{
      set(value){
        this.$store.state.sheetURL = value
      },
      get(){
        return this.$store.state.sheetURL;
      }
    },
    ruleName:{
      set(value){
        this.$store.state.ruleName = value
      },
      get(){
        return this.$store.state.ruleName;
      }
    },
    sheetID:{
      set(value){
        this.$store.state.sheetID = value
      },
      get(){
        return this.$store.state.sheetID;
      }
    },
    frequencyDisabled(){
      var x = true;
      if(this.frequency.text != '' && this.frequency != null && this.datePicker != null && this.datePicker != [] && this.datePicker != 'NaN-NaN-NaN' && this.timePicker != null && this.timePicker != [] && this.timePicker.toString().split(":")[0].trim() != "" && this.timePicker.toString().split(":")[1].trim() != "" && this.timePicker.toString().split(":")[1].toLowerCase().trim() != "mm" && this.timePicker.toString().split(":")[0].toLowerCase().trim() != "hh"){
        x = false;
      }
      else{
        x = true;
      }
      return x;
    },
    emailTemplatesData() {
      var x = "";
      if (
        (this.$store.state.EmailTemplates == undefined ||
          this.$store.state.EmailTemplates == null ||
          this.$store.state.EmailTemplates == "") &&
        this.userEmail != ""
      ) {
        this.showEmailTemplates();
      } else if (this.$store.state.EmailTemplates && this.userEmail != "") {
        x = this.$store.state.EmailTemplates.data;
      }
      return x;
    },
    dialogId: {
      get() {
        return this.$store.state.dialogId;
      },
      set(value) {
        this.$store.state.dialogId = value;
      }
    },
    templateActionBtnDisabled() {
      return this.$store.state.templateActionBtnDisabled;
    },
    selectedID() {
      return this.$store.state.selectedID;
    },
    stepperCampaignTemplate: {
      set(value) {
        this.$store.state.stepperCampaignTemplate = value;
      },
      get() {
        return this.$store.state.stepperCampaignTemplate;
      },
    },
    userEmail(){
      return this.$store.state.user.attributes.email;
    }
  },
  methods: {
    continueToSheet(){
      this.e1 = 2;
      this.datePicker = null;
      this.timePicker = '';
      this.$store.state.timePicker = "";
      this.frequency = {text: '' , value: ''},
      this.ruleName = "";
      this.sheetURL = "";
      this.sheetID = "";
      this.ruleSelectedID = null;
      this.sheetVerified = false;
      this.verifyingGoogleSheet = false;
      this.stepperCampaignTemplate = null;
    },
    copyToClipboard(){
      navigator.clipboard.writeText(this.serviceAccount);
      this.tooltipText = 'Service Account Copied';
    },
    mouseOutFunc(){
      this.tooltipText = 'Copy to clipboard';
    },
    dateAndTimeSubmitted(){

        let todayDate = new Date().getDate();
        let scheduledDate = new Date(this.datePicker).getDate()+1;
        
        var todayMonth = (new Date().getMonth())+1;
        var scheduledMonth = (new Date(this.datePicker).getMonth()+1);

        var todayYear = new Date().getFullYear();
        var scheduledYear = new Date(this.datePicker).getFullYear();

        var todayFullDate = new Date(todayYear + "-" + todayMonth + "-" + todayDate);
        var scheduleFullDate = new Date(scheduledYear + "-" + scheduledMonth + "-" + scheduledDate);

        // // same date
        // if(todayFullDate - scheduleFullDate === 0){
        // }
        // // future date
        // else if(todayFullDate - scheduleFullDate > 0){
        // }
        // // past date
        // else if(todayFullDate - scheduleFullDate < 0){
        // }

        /** if scheduled date's year is less than todays date's year */
        if(new Date(this.datePicker).getFullYear() < new Date().getFullYear()){
          this.$store.state.responseMsg = 'Kindly select some future date';
          this.$store.state.ResponseClass = 'error';
        }
        /** allow 4 digit year only */
        else if(new Date(this.datePicker).getFullYear().toString().length > 4){
          this.$store.state.responseMsg = 'Please enter valid year';
          this.$store.state.ResponseClass = 'error';
        }
        else if(todayFullDate - scheduleFullDate > 0){
          this.$store.state.responseMsg = 'Kindly select some future date';
          this.$store.state.ResponseClass = 'error';
        }
        else if(scheduledDate == todayDate && todayMonth == scheduledMonth){
          if(this.timePicker > this.getMinTime()){
            this.e1 = 5;
          }else{
            if(this.getMinTime().split(":")[0] >= "17"){
              this.$store.state.responseMsg = 'Kindly select a time for tomorrow';
              this.$store.state.ResponseClass = 'error';
            }else{
              this.$store.state.responseMsg = 'Kindly select a time that is no earlier than ' + this.getMinTime();
              this.$store.state.ResponseClass = 'error';
            }

          }
        }
        else{
          this.e1 = 5;
        }


      // var _this = this;
      // if(new Date(_this.datePicker).getTime() > new Date().getTime()){
      //   this.e1 = 5;
      // }
      // else{
        // if(this.timePicker > this.getMinTime()){
        //   this.e1 = 5;
        // }
        // else{
        //   // this.$store.state.responseMsg = 'Kindly select a time that is no earlier than '+this.tConvert(this.getMinTime());
        //   this.$store.state.responseMsg = 'Kindly select a time that is no earlier than ' + this.getMinTime();
        //   this.$store.state.ResponseClass = 'error';
        // }

      // }
      
    },
    getMinTime(){
      var currentTime = new Date();
      var hour = currentTime.getHours();
      var mins = currentTime.getMinutes();
      hour = hour + 1;
      if(hour>23){
        hour = 0;
      }
      if(hour<10){
        hour = '0'+hour;
      }
      if(mins <10){
        mins = '0'+mins;
      }
      return hour+':'+mins;
    },
    getTodayDate(){
      var today = new Date();
      var dd = today.getDate(); //min is one date more than today
      var mm = today.getMonth() + 1; //January is 0!
      var yyyy = today.getFullYear();

      if (dd < 10) {
        dd = '0' + dd;
      }

      if (mm < 10) {
        mm = '0' + mm;
      } 
          
      today = yyyy + '-' + mm + '-' + dd;
      return today;
    },
    saveScheduler(){
      var _this = this;
      if(_this.$store.state.rulesActionInProgress == false){
        _this.$store.state.rulesActionInProgress = true;
        _this.sheetID = new RegExp("/spreadsheets/d/([a-zA-Z0-9-_]+)").exec(_this.sheetURL)[1];
        var json = {
          "user_email": this.userEmail,
          "template_name": this.stepperCampaignTemplate,
          "rule_name": this.ruleName,
          "sheet_url": this.sheetURL,
          "sheet_id" : this.sheetID,
          "send_time": this.timePicker,
          "send_date": this.datePicker,
          "frequency": this.frequency.value,
          "new_entries": this.newEmailsOnly
        }
        console.log("here @ save scheduler AutoStepper @ 597");
        _this.$store.dispatch('setAutomationRule', json);
      }
    },
    verifyGoogleSheet(){
      if(new RegExp("/spreadsheets/d/([a-zA-Z0-9-_]+)").exec(this.sheetURL)){
        this.verifyingGoogleSheet = true;
        var sheetID = new RegExp("/spreadsheets/d/([a-zA-Z0-9-_]+)").exec(this.sheetURL)[1];
        this.$store.dispatch('googleSheetExist', sheetID);
      }
      else{
        this.$store.state.responseMsg = 'Kindly enter a valid Google Sheet URL';
        this.$store.state.ResponseClass = 'error';
      }
    },
    resetSheetVerification(){
      this.sheetVerified = false;
    },
    // resetStepper(){
    //   this.e1 = 1;
    //   this.stepperCampaignTemplate = null;
    //   this.sheetURL = null;
    //   this.ruleName = null;
    //   this.sheetID = null;
    //   this.timePicker = '00:00';
    //   this.datePicker = '';
    //   this.frequency = '';
    // },
    updateScheduler(){
      if(this.$store.state.rulesActionInProgress == false){
        this.$store.state.rulesActionInProgress = true;
        this.sheetID = new RegExp("/spreadsheets/d/([a-zA-Z0-9-_]+)").exec(this.sheetURL)[1];
        var json = {
          "user_email": this.userEmail,
          "template_name": this.stepperCampaignTemplate,
          "rule_name": this.ruleName,
          "sheet_url": this.sheetURL,
          "sheet_id" : this.sheetID,
          "send_time": this.timePicker,
          "send_date": this.datePicker,
          "frequency": this.frequency.value
        }
        this.$store.dispatch('updateAutomationRule', {ruleID: this.$store.state.automationRule.id, userJson: json})
      }
    },
    getDayName(dateStr, locale)
    {
      var date = new Date(dateStr);
      return date.toLocaleDateString(locale, { weekday: 'long' });        
    },
    // getMonthlyDate(dateStr){
    //   var x = new Date(dateStr);
    //   var date = x.getDay();
    // },
    getAllDaysInMonth(year, month, dayNumber) {
      var d = new Date(year, --month, 1);
      var dates = [];
      var daysToFirst = (dayNumber + 7 - d.getDay()) % 7;
      var firstOf = new Date(d.setDate(d.getDate() + daysToFirst));

      while (firstOf.getMonth() == month) {
        dates.push(new Date(+firstOf));
        firstOf.setDate(firstOf.getDate() + 7);
      }
      return dates;
    },
    changeTime(){
      if(this.timePicker == null || this.timePicker == undefined || this.timePicker == [] || this.timePicker == '00:00'){
        this.$store.state.timePicker = "";
        this.timePicker = '';
      }
    },
    changeDate(){
      var x, date, weeklyDay, weekdayOccurence, weekdays = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'], forMonth, occurence = ['first', 'second', 'third', 'fourth', 'fifth'], yearly;
      var today = new Date();
      // var epochTime = today.getTime();
      // epochTime = epochTime + 0/* + 86400000*/;
      // today = new Date(epochTime);
      var dd = today.getDate(); //min is one date more than today
      var mm = today.getMonth() + 1; //January is 0!
      var yyyy = today.getFullYear();

      if (dd < 10) {
        dd = '0' + dd;
      }

      if (mm < 10) {
        mm = '0' + mm;
      } 
          
      today = yyyy + '-' + mm + '-' + dd;
      // document.getElementById("datePicker").setAttribute("min", today);
      if(this.datePicker == null || this.datePicker == undefined || this.datePicker == []){
        this.datePicker = today;
      }
      if(this.datePicker){
        var parts = this.datePicker.split('-');
        date = new Date(parts[0], parts[1] - 1, parts[2]);
        weeklyDay = this.getDayName(date, 'en');
        forMonth = 'Monthly on the '+weeklyDay;
        weekdayOccurence = this.getAllDaysInMonth(date.getFullYear(), date.getMonth()+1, weekdays.indexOf(weeklyDay)+1);
        weekdayOccurence.some((element,index) => {
          x = new Date(element);
          if(date.getDate() == x.getDate() && (index+1) < weekdayOccurence.length){
            forMonth = {text:'Every Month', value:'Monthly on the '+occurence[index]+' '+weeklyDay};
          }
          else if(date.getDate() == x.getDate() && (index+1) == weekdayOccurence.length){
            forMonth = {text:'Every Month', value:'Monthly on the last '+weeklyDay};
          }
        });
        const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        yearly = {text:'Every Year', value:'Annually on '+monthNames[date.getMonth()]+' '+date.getDate()};
      }
      
      console.log("FI:" + JSON.stringify(this.frequencyItems));
      console.log("F: " + JSON.stringify(this.frequency));

      this.frequencyItems = [
        {text:'Daily', value:'Daily'}, /* {text:'Every Weekday (Monday to Friday)', value:'Every Weekday (Monday to Friday)'}, */ {text:'Every Week', value:'Weekly on '+weeklyDay}, forMonth, yearly, {text:'None', value: 'none'}];
        if(this.frequency.text && this.frequency.text != "" && this.frequency.text != "undefined" && this.frequency.text != undefined){
          if(this.frequency.text.toString().toLowerCase().includes("daily")){
            this.frequency = this.frequencyItems[0];
          }else if(this.frequency.text.toString().toLowerCase().includes("week")){
            this.frequency = this.frequencyItems[1];
          }else if(this.frequency.text.toString().toLowerCase().includes("month")){
            this.frequency = this.frequencyItems[2];
          }else if(this.frequency.text.toString().toLowerCase().includes("year")){
            this.frequency = this.frequencyItems[3];
          }else if(this.frequency.text.toString().toLowerCase().includes("none")){
            console.log("727");
            this.frequency = this.frequencyItems[4];
          }
        }
    },
    validNameAndURL(URL, Name) {
      var urlStatus = !/^(https?|ftp):\/\/([a-zA-Z0-9.-]+(:[a-zA-Z0-9.&%$-]+)*@)*((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3}|([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2}))(:[0-9]+)*(\/($|[a-zA-Z0-9.,?'\\+&%$#=~_-]+))*$/.test(URL);
      var nameStatus = !/([^\s])/.test(Name);
      if(urlStatus == true || nameStatus ==true){
        return true;
      }
      else{
        return false;
      }
    },
    defaultRadioValue() {
      if (this.emailTemplatesData[0]) {
        this.stepperCampaignTemplate = this.emailTemplatesData[0].name;
        if (document.querySelector("#templateRadio .active")) {
          document
            .querySelector("#templateRadio .active")
            .classList.remove("active");
          setTimeout(function () {
            document
              .querySelector("#templateRadio .v-item--active")
              .parentNode.classList.add("active");
          }, 100);
        } else {
          setTimeout(function () {
            document
              .querySelector("#templateRadio .v-item--active")
              .parentNode.classList.add("active");
          }, 100);
        }
      }
    },
    DownloadFileBlob(attachment_name, index){
      var _this = this;
      Storage.get(attachment_name, { download: true }).then(data=> {
          _this.downloadURL[index] = URL.createObjectURL(data.Body);
      });
    },
    downloadBlob(blob, filename, index) {
      //const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = blob;
      a.download = filename || 'download';
      const clickHandler = () => {
        setTimeout(() => {
          URL.revokeObjectURL(blob);
          a.removeEventListener('click', clickHandler);
        }, 150);
      };
      a.addEventListener('click', clickHandler, false);
      a.click();
      this.DownloadFileBlob(this.emailTemplatesData[index].attachment_name, index);
      return a;
    },
    viewEmailTemplate(item){
      this.$store.state.TemplateData = item;
    },
    openNewAddEmailTemplateBox(param){
      if(param == 'add'){
          this.$store.state.templateReplyTo = this.userEmail;
          this.$store.state.primary_btn_txt = 'Add';
          this.$store.state.emailTemplateHd = 'Create Email Template';
      }
      else{
          this.$store.state.primary_btn_txt = 'Update';
          this.$store.state.emailTemplateHd = 'Edit Email Template';
      }
      this.$store.state.addEmailTemplateBox = true;
    },
    openAddEmailTemplateBox(param, template){
      var _this = this;
      this.$store.state.templateID = template.id;
      this.$store.state.templateName = template.name;
      this.$store.state.templateFromName = template.from_name
      this.$store.state.templateFrom = template.sender.email;
      this.$store.state.templateSubject = template.subject;
      this.$store.state.templateBody = template.body;
      this.$store.state.templateReplyTo = template.reply_to;
      this.$store.state.emailTemplateAttachmentName = template.attachment_name;
      if(param == 'add'){
        this.$store.state.emailTemplateAttachment = [];
        this.$store.state.templateReplyTo = this.userEmail;
        this.$store.state.primary_btn_txt = 'Create';
        this.$store.state.emailTemplateHd = 'Create Email Template';
      }
      else{
        if(this.$store.state.templateReplyTo == '' || this.$store.state.templateReplyTo == undefined){
          this.$store.state.templateReplyTo = this.userEmail;
        }
        if(this.$store.state.emailTemplateAttachmentName){
          Storage.list(_this.$store.state.emailTemplateAttachmentName)
          .then(result => {
            _this.$store.state.emailTemplateAttachment = result[0];
            //eslint-disable-next-line
            _this.$store.state.emailTemplateAttachment.name = _this.$store.state.emailTemplateAttachmentName.replace(/\_@.*\./, '.');
          })
          .catch(err => console.log(err));
        }
        else{
          this.$store.state.emailTemplateAttachment = [];
        }
        this.$store.state.primary_btn_txt = 'Update';
        this.$store.state.emailTemplateHd = 'Edit Email Template';
      }
      this.$store.state.addEmailTemplateBox = true;
    },
    getOverflowClass(TempName) {
      var x = "";
      setTimeout(function () {
        if (document.getElementById(TempName + "-emailTempBody")) {
          var divHeight = document.getElementById(
            TempName + "-emailTempBody"
          ).clientHeight;
          var spanHeight = document.querySelector(
            "#" + TempName + "-emailTempBody" + " span"
          ).clientHeight;
          if (spanHeight > divHeight) {
            x = "overflow";
          } else {
            x = "no-overflow";
          }
        }
        if(document.querySelector("#" + TempName + "-emailTempBody" + " span")){
          document
          .querySelector("#" + TempName + "-emailTempBody" + " span")
          .classList.add(x);
        }
      }, 10);
    },
    showEmailTemplates() {
      this.$store.state.gettingTemplatesInProgress = true;
      this.$store.dispatch("getEmailTemplates", this.userEmail);
    },
    toSelectEmailTemplate(){
      if(this.sheetVerified){
        this.e1 = 3;
        var _this = this;
        if(document.querySelector("#templateRadio .v-item--active")){
            document.querySelector("#templateRadio .v-item--active").parentNode.classList.add("active");
        }
        this.defaultRadioValue();
        this.emailTemplatesData.forEach( function(item, index){
          if(item.attachment_name != '' && item.attachment_name != null && item.attachment_name != undefined && item.attachment_name.indexOf(' ') < 0){
              _this.DownloadFileBlob(item.attachment_name, index);
          }
          else{
              _this.downloadURL[index] = '';
          }
        });
      }
      else{
        this.$store.state.responseMsg = 'Kindly verify google sheet first';
        this.$store.state.ResponseClass = 'error';
      }
    },
    checkEmailTemplate() {
      if(this.e1 == 3){
        if (document.querySelector("#templateRadio .active")) {
          document
            .querySelector("#templateRadio .active")
            .classList.remove("active");
          setTimeout(function () {
            document
              .querySelector("#templateRadio .v-item--active")
              .parentNode.classList.add("active");
          }, 100);
        } else {
          setTimeout(function () {
            document
              .querySelector("#templateRadio .v-item--active")
              .parentNode.classList.add("active");
          }, 100);
        }
      }
    },
    deleteEmailTemplate(item){
      // this.$store.state.templateActionBtnDisabled = true;
      this.$store.state.templateActionBtnDisabled = false;
      this.$store.state.dialogId = item.id;
    },
    cancel(){
      this.$store.state.dialogId = '';
      this.$store.state.templateActionBtnDisabled = false;
    },
    tConvert (time) {
      var x = time;
      // Check correct time format and split into components
      if(time){
        time = time.toString().match (/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

        if (time.length > 1) { // If time format correct
          time = time.slice (1);  // Remove full string match value
          time[5] = +time[0] < 12 ? ' AM' : ' PM'; // Set AM/PM
          time[0] = +time[0] % 12 || 12; // Adjust hours
        }
        x = time.join (''); // return adjusted time or original string
      }
      return x;
    },
  },
  
  mounted() {
    // var _this = this;
    this.showEmailTemplates();
    this.changeDate();
    this.$store.state.timePicker = "";
  },
};
</script>
<style scoped>
.more-options button:first-child, .more-options a:first-child{
  margin-right: 5px;
}
.more-options button, .more-options a{
  opacity: 0.6;
}
.more-options button:hover, .more-options a:hover{
  opacity: 1;
}
div#templateRadio .v-card .more-options{
    transition: 0.2s all;
    min-height: 50px;
    background: linear-gradient(to bottom, rgba(249, 249, 250, 0), rgba(249, 249, 250, 1));
}
div#templateRadio .v-card .v-card__text{
    overflow-y: auto;
}
.v-stepper>>>.v-stepper__header .v-stepper__step:first-child{
    padding-left: 0px;
}
.v-stepper>>>.v-stepper__header .v-stepper__step:last-child{
    padding-right: 0px;
}
#campaignUploadStepper{
    position: relative;
    top: -24px;
}
.v-stepper__content{
    padding: 25px 0px 25px;
}
.v-stepper >>> .v-stepper__content.campaignUploaded{
    padding-top: 25px;
}
.v-stepper >>> .v-stepper__content #templateRadio .v-card{
    background-color: #F9F9FA;
}
.v-stepper >>> .v-stepper__content .v-card{
    margin-bottom: 25px !important;
}
.v-stepper >>> .v-stepper__content .scheduler .v-card{
    margin-bottom: 0px !important;
}
.drag-area{
  border: 2px dashed rgba(0,0,0,0.5);
  margin: auto;
  height: 400px;
  width: 600px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
}
.drag-area.active{
  border: 2px solid rgba(0,0,0,0.5);
}
.drag-area .NewUpload{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
}
.drag-area .NewUpload .error{
    padding: 5px 20px;
    border-radius: 5px;
    color: #fff;
}
.drag-area .NewUpload .icon{
  color: rgba(0,0,0,0.3);
}
.drag-area .NewUpload .icon span{
    font-size: 110px;
}
.drag-area .NewUpload header{
  font-size: 30px;
  font-weight: 500;
  color: rgba(0,0,0,0.5);
}
.drag-area .NewUpload span{
  font-size: 25px;
  font-weight: 500;
  color: rgba(0,0,0,0.5);
  margin: 10px 0 15px 0;
}

.drag-area .NewUpload img{
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 5px;
}
.drag-area .NewUpload input{
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    opacity: 0;
}
.upload-csv p{
    text-align: center;
    margin-top: 20px;
}
.upload-csv p>hr{
    display: inline-block;
    width: 180px;
    position: relative;
    top: -5px;
    border-color: rgba(0, 0, 0, 0.3);
}
.upload-csv p>hr:first-child{
    right: 20px;
}
.upload-csv p>hr:last-child{
    left: 20px;
}
#csv-file-container{
    width: 150px;
    position: relative;
    margin: 0 auto;
}
#browse-csv-file{
    position: relative;
    height: 35px;
    width: 100%;
    opacity: 0;
    font-size: 0px;
    top: 0px;
    cursor: pointer;
}
#csv-file-container:before{
    content: "Browse File";
    position: absolute;
    left: 0;
    padding: 5px 0;
    color: #fff;
    width: 100%;
    text-align: center;
    border-radius: 3px;
    background-color: #777cd2c7;
    cursor: pointer;
    transition: all 0.5s;
}
#csv-file-container:hover:before{
    background-color: #777BD2;
}
#templateRadio .v-card{
  max-height: 100% !important;
  overflow: hidden;
}
.getStarted .v-stepper__wrapper .v-card{
  overflow-x: hidden;
    overflow-y: auto;
    max-height: 55vh;
}
/* width */
.getStarted .v-card::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.getStarted .v-card::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
.getStarted .v-card::-webkit-scrollbar-thumb {
  background: #888; 
}

/* Handle on hover */
.getStarted .v-card::-webkit-scrollbar-thumb:hover {
  background: #555; 
}
.getStarted .selectEmailTemplate .v-card__title{
    text-align: center;
    display: block;
    background-color: #D9DAFF;
    padding: 10px;
}
.getStarted .selectEmailTemplate .v-card__text{
    padding: 15px;
    background-color: #F9F9FA;
    height: 180px;
}
.getStarted .selectEmailTemplate .v-card__text>div{
    display: block;
    /*height: 85%;*/
}
.getStarted .selectEmailTemplate .v-card__text>div>span{
    display: block;
}
.v-radio.theme--light>>>.v-input--selection-controls__input{
    margin: 15px auto;
    display: block;
}
.selectEmailTemplate .v-input.v-input--radio-group--row{
    width: 100%;
}
.selectEmailTemplate .addEmailTemplate .v-card__text{
    min-height: 280px;
    text-align: center;
}
.selectEmailTemplate .addEmailTemplate .v-card__text .v-icon{
    font-size: 130px;
}
.v-card__actions.text-right .right-btn{
    margin-left: auto !important;
}
.v-card .v-input>>>.v-messages__message{
    color: rgba(0,0,0,0.5) !important;
    padding: 0 0px;
}
.scheduler{
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0,0,0,0.3);
    max-width: 100%;
    z-index: 999;
}
.scheduler .v-picker{
    border-radius: 0;
}
.scheduler button{
    min-width: 100% !important;
}
.scheduler .v-picker--time{
    width: 100%;
}
.v-picker--time>>>.v-picker__title{
    padding: 9px !important;
}
.currentTime{
    background-color: #fff;
    padding: 15px;
}
.v-stepper.getStarted>>>span.v-stepper__step__step.primary{
    background-color: #777BD2 !important;
    border-color: #777BD2 !important;
}

.v-stepper.getStarted>>>.v-stepper__wrapper .v-card__actions .v-btn{
    min-width: 150px !important;
}
.success-tick{
    margin: 0 auto 15px;
}
.NewUpload {
    width: 100%;
    height: 100%;
    z-index: 9;
}
.Uploaded{
    position: absolute;
}
.NewUpload.hidden .icon, .NewUpload.hidden header, .NewUpload.hidden p, .NewUpload.hidden #csv-file-container{
    display: none;
}
div#templateRadio .col-12.active .v-card{
    border: 2px solid #777BD2;
    box-shadow: 0px 0px 10px rgb(0 0 0 / 25%) !important;
    /* transition: 0.3s all; */
}
.v-data-table >>> .v-data-table__wrapper > table > tbody > tr > td, .v-data-table >>> .v-data-table__wrapper > table > thead > tr > th{
    padding: 5px !important;
}
.v-data-table >>> header.v-sheet.v-toolbar{
    height: auto !important;
}
.confirmation-wrapper{
    padding: 40px 50px;
    background-color: #FAFAFA;
    text-align: center;
}
.confirmation-wrapper .card-footer button{
    padding: 10px 30px;
}
.confirmation-wrapper .card-footer button:last-child{
    margin-left:20px;
}
.confirmation-wrapper .card-header p{
    font-size: 18px;
    color: #575757;
    margin-bottom: 25px;
}
.confirmation-wrapper .card-footer .v-btn{
    min-width: 130px;
}
.v-dialog__content >>> .v-dialog{
    box-shadow:0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12) !important
}
.v-dialog__content >>> .v-dialog > .v-card > .v-card__title{
    padding: 16px 35px 10px;
}
.v-dialog__content >>> .v-card__actions{
    padding: 0px 35px 20px !important;
}
.v-dialog__content >>> .v-card__actions button{
    min-width: 125px;
}
.v-data-table >>> .v-data-footer .v-data-footer__select{
    display: none;
}
.v-data-table >>> .v-text-field{
    margin-top: 0px !important;
}
/* .dataTable{
    max-width: 400px;
    margin-left: auto;
} */
.v-data-table >>> .v-card .v-input .v-messages .error--text .v-messages__message{
    color: inherit !important;
}
/* .v-data-table >>> .v-data-table__wrapper tr td:nth-child(6) {
    display: grid !important;
    align-items: center;
} */
.emailTempBody>span{
    white-space: pre-wrap;
}
.upload-csv-actions .v-btn.hidden{
    visibility: hidden;
}
span.un-subscribed{
    color: red
}
span.material-icons.subscribed, span.material-icons.un-subscribed {
    opacity: 0.7;
    margin: auto;
    display: flex;
    width: min-content;
}
.v-picker>>>.v-picker__body{
    padding: 15px 0px;
}
tr.flagged {
    background-color: rgba(255, 0, 0, 0.2);
}
input{
  cursor: pointer;
}
.automation-scheduler .row.frequency{
  padding: 25px 15px 15px 15px;
  margin-left: auto;
  margin-right: auto;
}
.frequency .v-input__slot{
  width: 30%;
}
.automation-scheduler h3{
  margin-right: 15px;
  padding: 5px 0px;
  display: inline-block;
}
.automation-scheduler .row, .final-card .row{
  padding: 15px;
}
.automation-scheduler .col-12{
  padding-bottom: 3px;
}
.automation-scheduler .row:nth-child(2n-1), .final-card .row:nth-child(2n-1){
  background-color: #f5f5f5;
}
.automation-scheduler input{
  width: 150px;
}
.frequency .v-input{
  max-width: 300px;
}
.verify-btn.elevation-0{
  width: 100%;
  border-radius: 0;
  height: 32px !important;
  padding: 0px 15px;
}
#service-account, .time-picker >>> input, ::-webkit-calendar-picker-indicator {
  cursor: pointer;
}
::-webkit-calendar-picker-indicator{
  font-size: 20px;
}

.time-picker .time-custom-icon{
  max-width: 1.5em!important;
}

/* .vue__time-picker-dropdown ul li, .vue__time-picker .dropdown ul li.hint{
  position: sticky;
  top: 0;
  background: #fff;
  z-index: 999;
}

.time-picker >>>  .vue__time-picker-dropdown ul li, .vue__time-picker .dropdown ul li.hint{
  position: sticky;
  top: 0;
  background: #fff;
  z-index: 999;
} */

.time-picker >>> ul.hours li.hint {
  position: sticky;
  top: 0;
  background: #fff;
  z-index: 999;
}

.time-picker >>> ul.minutes li.hint {
  position: sticky;
  top: 0;
  background: #fff;
  z-index: 999;
}


</style>