<template>
  <v-container v-if="show">
    <automation-stepper id="automation-stepper"></automation-stepper>
    <template v-if="automationRule">
      <template>
        <v-row no-gutters class="subHeaderRow">
          <v-col cols="12" sm="12">
            <h1>Scheduled Campaign Rules</h1>
          </v-col>
        </v-row>
      </template>
      <v-row no-gutters>
        <v-simple-table id="recent-campaigns">
          <template v-slot:default>
            <thead style="background-color: #D9DAFF;">
              <tr>
                <th class="text-center">
                  Rule Name
                </th>
                <th class="text-center">
                  Sheet URL
                </th>
                <th class="text-center">
                  Email Template
                </th>
                <th class="text-center">
                  Date
                </th>
                <th class="text-center">
                  Time
                </th>
                <th class="text-center">
                  Frequency
                </th>
                <th class="text-center">Actions</th>
              </tr>
            </thead>
            <tbody>
              <template v-for="item in automationRule">
                <tr
                  :key="item.id"
                >
                  <td class="text-center">
                    {{ item.rule_name }}
                  </td>
                  <td class="text-center sheet-url">
                    <a :href="item.sheet_url" target="_blank">Spreadsheet</a>
                  </td>
                  <td class="text-center">
                    {{ item.template_name }}
                  </td>
                  <td class="text-center">
                    <span>{{ item.send_date }}</span>
                  </td>
                  <td class="text-center">
                    {{ tConvert(item.send_time) }}
                  </td>

                  <td class="text-center" v-if="
                      item.frequency.toString().toLowerCase().includes('annually') ||
                      item.frequency.value && item.frequency.value.toString().toLowerCase().includes('annually')
                  ">
                    Every Year
                  </td>
                  <td class="text-center" v-else-if="
                      item.frequency.toString().toLowerCase().includes('monthly') || 
                      item.frequency.value && item.frequency.value.toString().toLowerCase().includes('monthly')">
                    Every Month
                  </td>
                  <td class="text-center" v-else-if="
                      item.frequency.toString().toLowerCase().includes('weekly') || 
                      item.frequency.toString().toLowerCase().includes('week') ||
                      (item.frequency.value && item.frequency.value.toString().toLowerCase().includes('weekly')) ||
                      (item.frequency.value && item.frequency.value.toString().toLowerCase().includes('week'))
                    ">
                    Every Week
                  </td>
                  <td class="text-center" v-else-if="
                    item.frequency.toString().toLowerCase().includes('daily') ||
                    item.frequency.value && item.frequency.value.toString().toLowerCase().includes('daily')
                  ">
                    Daily
                  </td>
                  <td class="text-center" v-else-if="
                    item.frequency.toString().toLowerCase().includes('none') ||
                    item.frequency.value && item.frequency.value.toString().toLowerCase().includes('none')"
                  >
                    None
                  </td>
                  <td class="text-center" v-else>
                  </td>

                  <td class="more-options text-center">
                    <a @click="editRule(item)"><v-icon>edit</v-icon></a>
                    <transition name="fade-transition"
                      ><div class="update-automation-box" v-if="ruleSelectedID == item.id"><update-automation-rule
                        :rule="item"
                        @close="cancel()"
                      ></update-automation-rule></div>
                    </transition>
                    <a
                      @click="deleteCampaign(item)"
                    >
                      <v-progress-circular
                        indeterminate
                        color="#777BD2"
                        v-if="selectedID == item.id"
                      ></v-progress-circular>
                      <v-icon v-if="selectedID != item.id" class="deleteBtn"
                        >delete</v-icon
                      >
                    </a>
                    <transition name="fade-transition"
                      ><confirmation-dialog
                        v-if="dialogId == item.id"
                        actionName="deleteAutomationRule"
                        primaryTxt="Delete"
                        :item="item"
                        @close="cancel()"
                      ></confirmation-dialog
                    ></transition>
                  </td>
                </tr>
              </template>
            </tbody>
          </template>
        </v-simple-table>
        <template v-if="!automationRule.length && !fetching">
          <p class="empty-campaign-msg">
            No Rule has been added. Get started by adding a new rule.
          </p>
        </template>
      </v-row>
    </template>

    <div v-if="fetching" class="loader">
      <v-progress-circular
        indeterminate
        color="#777BD2"
        class="progressLoader"
      ></v-progress-circular>
    </div>
  </v-container>
</template>
<script>
import AutomationStepper from "@/components/AutomationStepper.vue";
import ConfirmationDialog from "@/components/ConfirmationDialog.vue";
import UpdateAutomationRule from '../components/updateAutomationRule.vue';
export default {
  name: "RulesManagment",
  components: {
    AutomationStepper, ConfirmationDialog,
    UpdateAutomationRule
  },
  data() {
    return {
      userEmail: "",
      valid: false,
      show: false,
      selectedID: null,
      generalRule: [(v) => !!v || "Field is required"],
      select: { state: 'Florida', abbr: 'FL' },
        items: [
          { state: 'Florida', abbr: 'FL' },
          { state: 'Georgia', abbr: 'GA' },
          { state: 'Nebraska', abbr: 'NE' },
          { state: 'California', abbr: 'CA' },
          { state: 'New York', abbr: 'NY' },
          { state: 'New York   1', abbr: 'NY 1' },
          { state: 'New York   2', abbr: 'NY 2' },
          { state: 'New York   3', abbr: 'NY 3' },
          { state: 'New York   4', abbr: 'NY 4' },
          { state: 'New York   5', abbr: 'NY 5' },
          { state: 'New York   6', abbr: 'NY 6' }
        ],
    };
  },
  created(){
    setTimeout(() => {
      this.scrollToView();
    }, 100);
  },
  methods: {
    scrollToView() {
      var elmnt = document.getElementById("automation-stepper");
      if(elmnt){
        elmnt.scrollIntoView();
      }
    },
    tConvert (time) {
      // Check correct time format and split into components
      time = time.toString ().match (/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

      if (time.length > 1) { // If time format correct
        time = time.slice (1);  // Remove full string match value
        time[5] = +time[0] < 12 ? ' AM' : ' PM'; // Set AM/PM
        time[0] = +time[0] % 12 || 12; // Adjust hours
      }
      return time.join (''); // return adjusted time or original string
    },
    getTemplates() {
      this.$store.dispatch("getEmailTemplates", this.userEmail);
    },
    deleteCampaign(item) {
      this.$store.state.campaignActionBtnDisabled = true;
      this.$store.state.dialogId = item.id;
    },
    editRule(item){
      this.$store.state.campaignActionBtnDisabled = true;
      this.ruleSelectedID = item.id;
    },
    cancel() {
      this.$store.state.dialogId = "";
      this.$store.state.ruleSelectedID = null;
      this.$store.state.campaignActionBtnDisabled = false;
    },
  },
  mounted() {
    if (this.$store.state.user == null || this.$store.state.user == undefined) {
      this.$router.push({ path: "/sign-in" });
    } else {
      if (this.$store.state.user.attributes["custom:role"] != "Admin") {
        this.$router.push({ path: "/" });
      } else {
        this.userEmail = this.$store.state.user.attributes.email;
        this.fetching = true;
        this.$store.dispatch("getAutomationRule", this.userEmail);
        this.$store.dispatch("getEmailTemplates", this.userEmail);
        this.show = true;
      }
    }
    console.log("Mounted");
    setTimeout(()=>{
      console.log(JSON.stringify(this.automationRule));
    }, 2500);
  },
  computed: {
    dialogId: {
      get() {
        return this.$store.state.dialogId;
      },
      set(value) {
        this.$store.state.dialogId = value;
      }
    },
    ruleSelectedID:{
      get(){
        return this.$store.state.ruleSelectedID;
      },
      set(value){
        this.$store.state.ruleSelectedID = value;
      }
    },
    automationRule(){
      return this.$store.state.automationRule;
    },
    templatesList() {
      var x = "";
      var list = [];
      if (
        this.$store.state.EmailTemplates == undefined ||
        this.$store.state.EmailTemplates == null
      ) {
        this.getTemplates();
      } else if (this.$store.state.EmailTemplates) {
        x = this.$store.state.EmailTemplates.data;
        // eslint-disable-next-line
        x.forEach((value, index) => {
          list.push(value.name);
        });
      } else {
        this.getTemplates();
      }
      return list;
    },
    campaignTemplate: {
      get() {
        return this.$store.state.campaignTemplate;
      },
      set(value) {
        this.$store.state.campaignTemplate = value;
      },
    },
    rulesActionInProgress() {
      return this.$store.state.rulesActionInProgress;
    },
    fetching: {
      set(value){
        this.$store.state.fetching = value;
      },
      get(){
        return this.$store.state.fetching;
      }
    },
  },
};
</script>
<style scoped>
.v-card.v-sheet {
  padding-bottom: 35px;
}
header.v-sheet.v-toolbar {
  height: auto !important;
}
header .header-heading {
  padding: 25px !important;
  width: 100%;
}
.v-card__text,
.v-card__actions {
  padding: 20px 75px 0px;
}
.v-card__actions button.v-btn {
  min-width: 150px;
}
.city-rules tr td:first-child {
  min-width: 150px;
  font-size: 16px;
  font-weight: 500;
}
h3 {
  margin: 15px 0;
}
.progressLoader.v-progress-circular {
  height: 100px !important;
  width: 100px !important;
  margin: auto;
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
}
.submitBtn .progressLoader.v-progress-circular {
  height: 30px !important;
  width: 30px !important;
}
.loader {
  text-align: center;
}
.collapsable-details {
  margin: 10px 0 25px;
}

.v-picker {
  border-radius: 0;
}
.v-picker--time {
  width: 100%;
}
.v-picker--time >>> .v-picker__title {
  padding: 9px !important;
}
.currentTime {
  background-color: #fff;
  padding: 15px;
}
.v-picker >>> .v-picker__body {
  padding: 15px 0px 0px;
}
.loader{
  text-align: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(255,255,255,0.5);
}
.progressLoader.v-progress-circular{
  height: 100px !important;
  width: 100px !important;
  margin: 15px auto;
}
.subHeaderRow {
  padding: 25px 0px;
}
.empty-campaign-msg {
  width: 100%;
  text-align: center;
  font-size: 20px;
  padding: 25px 0px 15px;
}
.sheet-url{
  max-width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.update-automation-box{
  position: fixed;
  background-color: rgba(0,0,0,0.5);
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
}
</style>